<template>
    <div v-if="!isAccess" class="alert alert-warning my-3">{{ messageError }}</div>
    <div v-else id="dashboard-content">
      <router-link :to="`/user/penilaian/detail/${book_id}`"><i class="fa fa-arrow-left mr-2"></i> Kembali</router-link>
      <nav class="mt-2">
        <ol class="breadcrumb p-0 bg-transparent">
          <li class="breadcrumb-item">
            <router-link to="/user/penilaian" class="text-muted">
              Daftar Penilaian Buku
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`/user/penilaian/detail/${book_id}`" class="text-muted">
              {{ bookPublisher.title }}
            </router-link>
          </li>
          <li class="breadcrumb-item active text-dark" aria-current="page">Hasil Penilaian</li>
        </ol>
      </nav> 
  
      <h4>Hasil Penilaian Buku</h4>
  
      <div class="mt-3">
         
        <div class="card mb-3 border-0 shadow-sm py-1 px-3">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="m-0">Hasil Penilaian</h5>
              <router-link target="_blank" :to="`/user/penilaian/hasil-turnitin/${book_id}`" class="badge bg-warning text-dark p-2">Lihat Hasil Uji Kemiripan</router-link>
            </div>
            <div v-if="bookSummary.recommended == 1 && bookSummary.summary_type == 'penilaian'">
              <p>
                <b>Selamat!</b> Buku bapak/ibu masuk dalam kategori revisi minor. Berikut ini beberapa komentar hasil penilaian yang harus segera direvisi.
                Harap membaca kekurangan buku dan halaman terkait dengan seksama. <br>
              </p>
              <p>Bapak/ibu dapat melaporkan hasil revisi dengan mengklik tombol "Laporkan", kemudian mengisi status revisi, perubahan halaman (jika berubah), dan catatan penting untuk kami.</p>
              <div class="my-2 p-2 rounded" style="background: #f2f2f2;">
                <div class="mb-2">
                  Contoh penulisan halaman baru : <br>
                  21 [22], 31 [32], 41 [42]
                </div>
                <div>
                  21 adalah halaman awal <br>
                  [22] adalah halaman baru
                </div>
              </div>
            </div>
            <div class="my-4">
              <div class="text-muted">Status</div>
              <div v-if="bookSummary?.recommended == 1">
                <span class="bg-success-100 text-white d-inline-block mt-2 py-2 px-3 rounded-pill">
                  {{ bookSummary?.revision }}
                </span>
              </div>
              <div v-else>
                <span class="bg-secondary-50 text-secondary d-inline-block mt-2 py-2 px-3 rounded-pill">
                  {{ bookSummary?.revision }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="text-muted mb-1">Catatan</div>
                <span v-html="bookSummary?.summary"></span>
              </div>
              <div v-show="bookSummary?.step == 'Pleno'" class="col-lg-6">
                <div class="text-muted mb-1">Keterangan Tambahan</div>
                <div v-html="bookPublisher?.event_description"></div>
              </div>
            </div>
          </div>            
        </div>
  
        <!-- Feedback Publisher -->
        <div class="card mb-3 border-0 shadow-sm py-1 px-3">
          <div class="card-body">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" :src="iframeMatrix"></iframe>
            </div>
            <!-- <h5>Temuan</h5>
            
            <div class="form-group">
              <input v-model="search" type="text" class="form-control" placeholder="Cari kata kunci">
            </div>
            <div class="table-responsive">
              <table class="table table-bordered" style="font-size: .8rem;">
                  <tr>
                    <th>Kekurangan/Kelemahan Buku</th>
                    <th>Halaman Terkait</th>
                    <th>Status Perbaikan</th>
                    <th>Halaman Baru</th>
                    <th>Catatan</th>
                    <th v-show="isShowReport">Opsi</th>
                  </tr>
                <tbody>
                  <tr v-if="filteredTodolistPaginated.length == 0">
                    <td colspan="6" class="text-center py-3">Kata kunci tidak ditemukan</td>
                  </tr>
                  <tr v-else v-for="(todolist, index) in filteredTodolistPaginated" :key="index">
                    <td>[{{ todolist.subcomponent }}] {{ todolist.command }}</td>
                    <td>{{ todolist.page || '-' }}</td>
                    <td>{{ todolist.feedback_tag || '-' }}</td>
                    <td>{{ todolist.feedback_page || '-'}}</td>
                    <td>{{ todolist.feedback_content || '-'}}</td>
                    <td v-show="isShowReport">
                      <a href="#" class="btn btn-sm btn-warning" @click="getDetailFeedback(todolist.feedback_id, todolist.id)" data-toggle="modal" data-target="#matrixModal" data-whatever="@mdo">
                        Laporkan
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center">
                <pagination
                  :key="totalPage"
                  :total-page="totalPage"
                  :current-page="pagination.currentPage"
                  :total-data="todolistBook.length"
                  @change="onChangePage"
                >
                </pagination>
              </div>
            </div>             -->
          </div>            
        </div>
        <!-- End Feedback Publisher -->
  
      </div>
  
      <!-- Modal Popup Lihat Info -->
      <div class="modal fade" id="popupModal" tabindex="-1" role="dialog" aria-labelledby="popupModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="popupModalLabel">Detail</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Hasil pengisian instrumen oleh staff dan penilai</p>
              <table class="table">
                <tbody>
                  <tr>
                    <td>Catatan Umum</td>
                    <td>:</td>
                    <td>{{ notes }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Modal Matrix -->
      <div class="modal fade" id="matrixModal" tabindex="-1" role="dialog" aria-labelledby="matrixModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="matrixModalLabel">Laporkan Status Terbaru</h5>
              <button type="button" id="close" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="submitFeedback()">
              <div class="modal-body">
                <div class="form-group">
                  <label for="status" class="col-form-label">Status</label>
                  <p class="form-text text-muted">Status pada form ini menunjukan bahwa buku ini telah siap diperiksa, pilih selesai jika sudah diperbaiki.</p>
                  <select v-model="feedback.tag" id="status" class="form-control">
                    <option value="Selesai">Selesai</option>
                    <option value="Belum Selesai">Belum Selesai</option>
                  </select>
                  
                </div>
                <div class="form-group">
                  <label for="halaman" class="col-form-label">Halaman Baru</label>
                  <p class="form-text text-muted">Harap mencantumkan halaman baru pada buku jika ada perubahan. Untuk memudahkan kami dalam mengecek konten revisi.</p>
                  <input required v-model="feedback.page" type="text" class="form-control" id="halaman" placeholder="Halaman baru">
                </div>
                <div class="form-group">
                  <label for="catatan" class="col-form-label">Catatan</label>
                  <p class="form-text text-muted">Berikan catatan khusus untuk kami dalam perubahan revisi ini.</p>
                  <textarea required v-model="feedback.content" class="form-control" id="catatan" placeholder="Tulis catatan" rows="5"></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button v-if="!loading" type="submit" class="btn btn-primary">Kirim</button>
                <button v-else type="button" class="btn btn-primary disabled">
                  Loading..
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    
    </div>
  
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex'
  import moment from "moment";
  moment.locale("id");
  
  export default {
    name: "User-Penilaian-Hasil",
    data() {
      return {
        isAccess: true,
        messageError: '',
        book_id: this.$route.params.id ?? null,
        token: localStorage.getItem('token'),
        notes: "",
        showAll: false,
        todolistBook: [],
        search: "",
        loading: false,
        feedback : {
          page: "",
          tag: "Belum Selesai",
          content: "",
        },
        pagination: {
          currentPage: 1,
          maxPage: 5,
          pageSize: 10
        }
      }
    },
    computed: {
      ...mapState(['bookPublisher', 'bookSummary']),
      iframeMatrix() {
        return `https://penilaian-buku.cloudapp.web.id/penerbit/revisi/${this.book_id}/?authorization=${this.token}`
      },
      isShowReport() {
        // Hide button report if status 'layak' & 'tidak layak'
        if(this.bookSummary?.recommended == '1' || this.bookSummary?.recommended == '0') {
          return false
        }
        return true
      },
      filteredTodolist() {
        return this.todolistBook.filter(todolist =>
          todolist.subcomponent.toLowerCase().includes(this.search.toLowerCase()) ||
          todolist.command.toLowerCase().includes(this.search.toLowerCase()) ||
          todolist.page.toLowerCase().includes(this.search.toLowerCase())
        );
      },
      filteredTodolistPaginated() {
        const from = (this.pagination.currentPage - 1) * this.pagination.pageSize;
        const to = from + this.pagination.pageSize;
        return this.filteredTodolist.slice(from, to);
      },
      totalPage() {
        return Math.ceil(this.filteredTodolist.length / this.pagination.pageSize);
      },
    },
    methods: {
      ...mapActions(['fetchBookByPublisher', 'fetchBookSummary', 'fetchTodolistByBook', 'sendFeedback', 'fetchDetailFeedback']),
      getDetailFeedback(feedback_id, todolist_id) {
        // Reset previous data
        this.feedback.id = ""
        this.feedback.tag = "Belum Selesai"
        this.feedback.page = ""
        this.feedback.content = ""
        this.feedback.todolist_id = ""
  
        if (feedback_id == null) {
          // Mode insert data dengan todolist id
          // Mode form ke send.
          this.feedback.todolist_id = todolist_id
        } else {
          this.fetchDetailFeedback(feedback_id).then(response => {
            this.feedback.id = response.data.id
            this.feedback.tag = response.data.tag
            this.feedback.page = response.data.page
            this.feedback.content = response.data.content 
          });
        }
      },
      getDay(date) {
        return moment(date).format('dddd')
      },
      parseDate(date) {
        return moment(date).format('LL');
      },
      onChangePage(page) {
        this.pagination.currentPage = page;
      },
      submitFeedback() {
        this.loading = true;
        this.sendFeedback(this.feedback).then(response => {
          response.data.status == 'success' && this.fetchTodolistByBook(this.book_id).then( responseTodolist => {
            this.todolistBook = responseTodolist.data;
            document.getElementById('close').click();
            this.loading = false;
          })
        })
      }
    },
    created() {
      const id = this.$route.params.id
      this.fetchBookByPublisher(id).catch(error => {
        if(error.response.status == 401) {
          this.messageError = "Buku tidak dapat diakses."
        }
        if(error.response.status == 404) {
          this.messageError = "Buku tidak ditemukan."
        }
        this.isAccess = false
      })
      this.fetchBookSummary(id)
      this.fetchTodolistByBook(id).then(response => this.todolistBook = response.data)
    },
    beforeRouteEnter(_, __, next) {
      const { role_name } = JSON.parse(localStorage.getItem("user"));
      if (role_name === "Penerbit") next();
      else next("/user/home");
    },
  }
  </script>